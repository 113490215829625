<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <img
          src="@/assets/images/gif/progress.gif"
          class="img-fluid"
          alt="logo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';

export default {
  name: 'CallbackFacebook',
  data () {
    return {
      globalUserObject: {}
    };
  },
  mixins: [goToUserProfile],
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  mounted () {
    this.getFacebookResponse();
  },
  methods: {
    getFacebookResponse () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code');
      const scope = urlParams.get('scope');
      const prompt = urlParams.get('prompt');
      const authuser = urlParams.get('authuser');
      this.backendCallback(authuser, code, scope, prompt);
    },
    ...mapActions({
      signInProvider: 'auth/signInProvider'
    }),
    async backendCallback (authUser, code, scope, prompt) {
      await axios
        .get(process.env.VUE_APP_BASE_URL + '/api/login/facebook/callback', {
          params: {
            code: code,
            scope: scope,
            authuser: authUser,
            prompt: prompt
          }
        })
        .then(async (response) => {
          await this.signInProvider(response.data.token);
          this.goToUserProfile(this.currentUser);
        }).catch(() => {
          this.$swal.fire({
            text: this.$t('loginFacebookErrorMessage')
          }).then(() => {
            this.$router.push({ name: 'auth1.sign-in1' });
          });
        })
    }
  }
};
</script>
<style>
.size {
  height: 900px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
